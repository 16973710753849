function transformProductTitle({
  quantity,
  productSize,
  packagingType = '',
  productScent = '',
  productTitle,
  withBracket = true,
}: {
  quantity?: number;
  productSize: string;
  packagingType?: string | null;
  productScent: string | null;
  productTitle: string;
  withBracket?: boolean;
}): string {
  if (packagingType && ![quantity, productSize, productScent, productTitle].some(Boolean))
    return '';

  const productSizeSpace = productSize ? ' ' : '';
  const quantitySubString = quantity ? `${quantity} x ` : '';
  const packagingTypeSubString = packagingType ? `${productSizeSpace}${packagingType}` : '';
  const productScentSubString = productScent ? ` ${productScent}` : '';
  const openBracket = withBracket ? '(' : '';
  const closeBracket = withBracket ? ')' : '';

  return `${quantitySubString}${productTitle} ${openBracket}${
    productSize || ''
  }${packagingTypeSubString}${productScentSubString}${closeBracket}`;
}

export default transformProductTitle;
