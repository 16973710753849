import transformProductTitle from 'utils/stringUtils/transformProductTitle';

export const mergeProductsData = (cartItems, cartProductsData) => {
  const productsArr = cartItems.map((item) => {
    const cmsItem =
      cartProductsData?.nodes?.find((cmsProduct) => cmsProduct.skuId === item.variant.sku) || {};

    const { quantityAvailable, variant, id } = item;
    const { price, compareAtPrice, sku } = variant;

    const {
      images,
      title,
      shortDescription,
      productInfo,
      productSize,
      packagingType,
      productScent,
    } = cmsItem;

    const subTitle = transformProductTitle({
      productTitle: '',
      productSize,
      packagingType,
      productScent,
      withBracket: false,
    });

    return {
      price,
      productInfo,
      quantity: item?.quantity,
      title: subTitle?.length > 1 ? `${title} - ${subTitle}` : title,
      sku,
      link: cmsItem?.link,
      availableQuantity: quantityAvailable,
      image: images?.[0],
      oldPrice: compareAtPrice,
      description: shortDescription,
      variantId: variant?.id,
      productId: id,
    };
  });

  return productsArr || [];
};
