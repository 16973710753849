import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import SignUpFormPopupComponent from './components/SignUpFormPopupComponent';

const SignUpFormPopup = (props) => (
  <GoogleReCaptchaProvider
    reCaptchaKey={process.env.GATSBY_GOOGLE_CAPTCHA_PUBLIC}
    scriptProps={{ async: true, defer: false, appendTo: 'body' }}
  >
    <SignUpFormPopupComponent {...props} />
  </GoogleReCaptchaProvider>
);

export default SignUpFormPopup;
