import React, { FC } from 'react';
import classNames from 'classnames';

import { IconProps } from './models';
import './IconCustom.scss';

const Icon: FC<IconProps> = ({ icon, classes, colorCount }) => (
  <span className={classNames('icon', classes, { [`icon--${icon}`]: icon })}>
    {colorCount && colorCount > 1 ? (
      <>
        {[...Array(colorCount)].map((_value, index) => (
          <span className={`path${index + 1}`} key={`path${index + 1}`} />
        ))}
      </>
    ) : null}
  </span>
);

export default Icon;
