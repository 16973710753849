import axios, { AxiosError } from 'axios';

import { gtmService } from 'utils/gtmService';

import {
  SignUpRequestParams,
  FormDateFields,
  FormStateFields,
  SubmitFormDataParams,
} from './model';
import { CHECKBOX_NAME, EMAIL_OPT_FLAG_YES, EMAIL_OPT_FLAG_NO, NOT_PROVIDED } from './constants';

const onSuccessCallback = (
  setFormState: React.Dispatch<React.SetStateAction<FormStateFields>>,
  callbackFunction?: () => void
) => {
  setFormState((oldState) => ({
    ...oldState,
    isSent: true,
    isSubmitted: true,
  }));
  gtmService.emitGenerateLead(gtmService.formNames.newsletter);
  if (callbackFunction) {
    callbackFunction();
  }
};

const onErrorCallback = (
  setFormState: React.Dispatch<React.SetStateAction<FormStateFields>>,
  error: AxiosError
) => {
  if (process.env.NODE_ENV !== 'production') console.error(error);
  setFormState({
    isSent: false,
    isSending: false,
    isError: true,
    isSubmitted: true,
  });
};

const sendSignUpRequest = async ({
  url,
  body,
  setFormState,
  callbackFunction,
}: SignUpRequestParams) => {
  axios
    .post(url, body)
    .then(() => {
      onSuccessCallback(setFormState, callbackFunction);
    })
    .catch((error) => {
      onErrorCallback(setFormState, error);
    });
};

const handleChangeFormData = (
  e: React.FormEvent<HTMLInputElement>,
  setFormData: React.Dispatch<React.SetStateAction<FormDateFields>>,
  setFormState: React.Dispatch<React.SetStateAction<FormStateFields>>
) => {
  const { name, value, type, checked } = e.currentTarget;
  const newValue = type === CHECKBOX_NAME ? checked : value;
  setFormData((oldFormData) => ({
    ...oldFormData,
    [name]: newValue,
  }));
  setFormState((prevVal) => ({ ...prevVal, isError: false }));
};

const submitFormData = async ({
  setFormState,
  formConfig,
  isCDSMode,
  url,
  callbackFunction,
  formData,
  recaptchaResponse,
}: SubmitFormDataParams) => {
  setFormState((oldState) => ({
    ...oldState,
    isSending: true,
    isSubmitted: true,
  }));

  let body = {};

  const {
    body: configBody = '',
    headers = '',
    revisionIds = '',
    cdp = '',
  } = formConfig?.[0]?.properties || {};

  if (isCDSMode) {
    const today = new Date();

    const bodyToCreate = configBody
      .replace(/\$EmailAddress\$/gi, formData.email)
      .replace(/\$name\$/gi, formData.firstName || NOT_PROVIDED)
      .replace(/\$last_name\$/gi, formData.lastName || NOT_PROVIDED)
      .replace(/\$phone\$/gi, formData.phone)
      .replace(/\$Agreements$/gi, JSON.stringify(revisionIds))
      .replace(/\$formTime\$/gi, `${today}`)
      .replace(/\$regTime\$/gi, `${today.toISOString()}`);

    try {
      const parsedBodyToCreate = JSON.parse(bodyToCreate);

      parsedBodyToCreate.JsonExternalData.Agreements = revisionIds;

      const headersObject = JSON.parse(headers) || {};

      const cdpFromHeaders = {
        programCode: headersObject['program-code'],
        brandOrgCode: headersObject['brand-org-code'],
        accountSource: headersObject['account-source'],
      };

      body = {
        headers: headersObject,
        data: {
          ...parsedBodyToCreate,
          JsonExternalData: {
            ...parsedBodyToCreate.JsonExternalData,
            UnmappedAttributes: {
              Email_Optin: formData.extraPolicy,
              Accept_targeting: formData.policy,
              Accept_profiling: formData.additionalPolicy,
            },
            ProfileSubscriptions: parsedBodyToCreate.JsonExternalData.ProfileSubscriptions?.map(
              (subscription) => ({
                ...subscription,
                OptStatus: formData.extraPolicy,
                JsonExternalData: {
                  ...subscription.JsonExternalData,
                  EmailOptFlag: formData.extraPolicy ? EMAIL_OPT_FLAG_YES : EMAIL_OPT_FLAG_NO,
                },
              })
            ),
          },
        },
        cdp: JSON.parse(cdp) || cdpFromHeaders,
        recaptchaResponse,
      };
    } catch (error) {
      if (process.env.NODE_ENV !== 'production') console.error('Error parsing JSON:', error);
    }
  } else {
    body = {
      data: {
        JsonExternalData: {
          UnmappedAttributes: {
            Email_Optin: formData.extraPolicy,
            Accept_targeting: formData.policy,
            Accept_profiling: formData.additionalPolicy,
          },
        },
        first_name: formData.firstName,
        last_name: formData.lastName || NOT_PROVIDED,
        email: formData.email,
      },
      recaptchaResponse,
    };
  }

  if (url) {
    sendSignUpRequest({ url, body, setFormState, callbackFunction });
  }
};

export { sendSignUpRequest, handleChangeFormData, submitFormData };
