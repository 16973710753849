import React, { FC, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import classNames from 'classnames';
import { ISingupFormPlaceholderFront } from '@shared/types/frontend/compositions';

import { Container } from 'layout';
import GatsbyImage from 'common/GatsbyImage';

import Form from './Form';

import '../SignUp.scss';
import { submitFormData } from '../actions';
import { initialFormState } from '../formConstants';
import { NEWSLETTER_SIGN_UP } from '../constants';

const SignUpComponent: FC<ISingupFormPlaceholderFront> = ({
  singupFormBaner = {},
  renderForm,
  wideMode,
  alignImage,
  image,
  thankYouComponent,
  callbackFunction,
}) => {
  const { errorMessage, formConfig } = singupFormBaner || {};
  const { isCDSMode, isCheckboxRequired } = formConfig?.[0]?.properties || {};
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [formState, setFormState] = useState(initialFormState);
  const [formData, setFormData] = useState({
    first_name: '',
    lastName: '',
    email: '',
    phone: '',
    accepts_marketing: true,
    marketing_opt_in_level: 'confirmed_opt_in',
    requiredCheckbox: Boolean(isCheckboxRequired),
    policy: false,
  });

  const sectionCSSClasses = classNames(
    'signup',
    {
      [`signup--image-${alignImage?.[0]}`]: alignImage,
      'signup--wide-mode': wideMode,
    },
    'signup--dark-theme'
  );

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!executeRecaptcha) {
      return;
    }

    const recaptchaResponse = await executeRecaptcha(NEWSLETTER_SIGN_UP);

    const formDataSubmit = {
      firstName: formData.first_name,
      email: formData.email,
      phone: formData.phone,
      lastName: formData.lastName,
      policy: formData.policy,
      acceptsMarketing: formData.accepts_marketing,
      marketingOptInLevel: formData.marketing_opt_in_level,
      requiredCheckbox: formData.requiredCheckbox,
    };

    await submitFormData({
      setFormState,
      formConfig,
      isCDSMode,
      url: process.env.GATSBY_CDP_LAMBDA_URL,
      callbackFunction,
      formData: formDataSubmit,
      recaptchaResponse,
    });
  };

  const ThankYou = thankYouComponent;

  return renderForm ? (
    <div className={sectionCSSClasses} data-testid="signup">
      <Container fluid>
        <div className="signup__grid">
          {image?.length ? (
            <div className="signup__image">
              <GatsbyImage
                image={image[0].properties.image}
                objectFit={wideMode ? 'cover' : 'contain'}
                className="signup__image-img"
                alt={image[0].properties.imageAlt}
              />
            </div>
          ) : null}
          {!formState.isSent ? (
            <Form
              {...{
                ...singupFormBaner,
                formData,
                setFormData,
                handleSubmit,
                formState,
                errorMessage,
                setFormState,
                isCheckboxRequired,
              }}
            />
          ) : null}
          {formState.isSent && !!ThankYou ? ThankYou : null}
        </div>
      </Container>
    </div>
  ) : null;
};

export default SignUpComponent;
