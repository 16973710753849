import { useState, useCallback, useEffect } from 'react';

import { POPUP_CONFIG } from '../constants';
import { HookReturnValue } from '../model';

export default function useBrowserSession(delayTimeout?: number): HookReturnValue {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem(POPUP_CONFIG.name)) return;

    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, delayTimeout || POPUP_CONFIG.delayTimeout);

    return () => clearTimeout(timeout);
  }, []);

  const onCloseHandler = useCallback(() => {
    sessionStorage.setItem(POPUP_CONFIG.name, POPUP_CONFIG.state.seen);
    setIsVisible(false);
  }, []);

  return [isVisible, onCloseHandler];
}
