export function shouldRenderOnPage<
  T extends {
    disableOnPages?: { url: string }[];
  }
>(target: T, link: string): boolean {
  if (!target) return false;

  if (Array.isArray(target.disableOnPages) && target.disableOnPages.length) {
    return !target.disableOnPages.some(({ url }) => url === link);
  }

  return true;
}
