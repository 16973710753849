import React, { FC } from 'react';
import classNames from 'classnames';
import { Header } from '@shared/types/umbraco/compositions';
import { Container } from 'layout';
import LanguageSelector from 'common/LanguageSelector/LangaugeSelector';
import TopHeaderBlock from './components/TopHeaderBlock';
import './TopHeader.scss';

const TopHeader: FC<Header.ITopHeader> = ({
  isMenuOpen,
  topHeaderLeftBlock,
  languageSelector,
  topHeaderRightBlock,
  currentLang,
  alternateUrls,
  link,
}) => {
  const headerTopClassname = classNames(
    'header__top ',
    { open: isMenuOpen },
    'header__top--dark-theme'
  );

  return (
    <div data-testid="top-header" className={headerTopClassname}>
      <Container fluid>
        <div className="header__top-flex">
          <TopHeaderBlock block={topHeaderLeftBlock} />
          {languageSelector?.length ? (
            <LanguageSelector
              inHeader={languageSelector[0].properties.inHeader}
              langs={languageSelector[0].properties.langs}
              redirectToHome={languageSelector[0].properties.redirectToHome}
              currentLang={currentLang}
              alternateUrls={alternateUrls}
              link={link}
            />
          ) : null}
          <TopHeaderBlock block={topHeaderRightBlock} />
        </div>
      </Container>
    </div>
  );
};

export default TopHeader;
