const POPUP_CONFIG = {
  name: 'signupPopupState',
  state: {
    seen: 'seen',
    initial: 'initial',
  },
  delayTimeout: 10000,
  expire: 30,
};

const POPUP_CONTENT_VARIANT = {
  DEFAULT: 'default',
  DISCOUNT: 'discount',
};

export { POPUP_CONFIG, POPUP_CONTENT_VARIANT };
