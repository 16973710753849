import { IExtractor } from '../models';

const extractor = ({
  pageHref,
  brandSettings,
  additionalData,
  transformedSiteUrl,
}: IExtractor): object => ({
  '@context': 'https://schema.org/',
  '@type': 'Product',
  name: additionalData.title.replace(/(<(\/?[^>]+)>)/g, ''),
  image: additionalData.image
    ? `${transformedSiteUrl}${additionalData.image?.gatsbyImage?.childImageSharp?.fluid.src}`
    : additionalData.image,
  description: additionalData.description?.replace(/(<(\/?[^>]+)>)/g, ''),
  brand: {
    '@type': 'Brand',
    name: brandSettings.brandName,
  },
  sku: additionalData.sku,
  offers: {
    '@type': 'Offer',
    url: pageHref,
    priceCurrency: additionalData.priceCurrency || '',
    price: additionalData.price || '0',
    availability: additionalData.quantityAvailable
      ? 'https://schema.org/InStock'
      : 'https://schema.org/OutOfStock',
  },
});

export default extractor;
