import { useCallback, useContext, useEffect, useMemo } from 'react';
import { currenciesIcons } from 'context/CartContextProvider/currenciesIcons';
import { CartContext } from 'context/CartContextProvider/CartContextProvider';
import { IProductInfo } from '../../common/AddToCartComponent/model';
import gtmCustomEvents from '../../utils/gtmCustomEvents';
import { getDiscount, getDiscountPercent, mergeProductsData } from './utils';
import { TUseShopifyRes, TUseShopifyArgs } from './model';

const useShopify = ({
  isShopify,
  lang,
  skuId,
  shopifyId,
  cartProductsData,
  productTitle,
}: TUseShopifyArgs): TUseShopifyRes => {
  const {
    purchaseSettings,
    allShopProducts,
    createClient,
    addProductToCheckout,
    cartItems,
    setIsModalOpen,
    setCurrentProduct,
  } = useContext(CartContext);

  const {
    addToCartTitle,
    soldOutTitle,
    discountPlaceHolder,
    addToCartLabel = '',
    soldOutLabel = '',
  } = purchaseSettings;

  useEffect(() => {
    if (isShopify) {
      createClient(isShopify, lang);
    }
  }, [isShopify, lang]);

  const productInfo: IProductInfo = useMemo(() => {
    if (!allShopProducts || !shopifyId || !skuId) return {};
    const neededProduct = allShopProducts?.[skuId];

    if (!neededProduct) return {};

    return {
      ...neededProduct,
      currencieLabel: currenciesIcons[neededProduct?.priceV2?.currencyCode],
    };
  }, [JSON.stringify(allShopProducts), shopifyId, skuId]);

  const mergeCmsAndCartProductsData = useCallback(
    (newCartItems) => mergeProductsData(newCartItems, cartProductsData),
    [JSON.stringify(cartItems), JSON.stringify(cartProductsData)]
  );

  const { compareAtPrice, price, id, sku } = productInfo;

  const addProduct = (quantity) =>
    addProductToCheckout(id, quantity).then((newCartItems) => {
      const currentModalProduct = mergeCmsAndCartProductsData(newCartItems).find(
        (product) => product.sku === sku
      );

      if (currentModalProduct) setCurrentProduct(currentModalProduct);

      gtmCustomEvents(
        {
          add: {
            actionField: {
              list: 'Shopping cart',
            },
            products: [
              {
                id,
                name: currentModalProduct.title,
                quantity: currentModalProduct.quantity,
                price: currentModalProduct.price,
                category: 'Bundles',
                brand: 'Finish',
              },
            ],
          },
        },
        'eec.add'
      );
    });

  const checkoutAndAddProduct = (orderQuantity = 1) =>
    addProduct(orderQuantity).then(() => setIsModalOpen(true));

  const discount = getDiscount(compareAtPrice, price);

  const discountPercent = getDiscountPercent(discount, compareAtPrice);

  const discountString = discountPercent
    ? discountPlaceHolder
        ?.replace(/%percentage%/i, `${discountPercent}`)
        .replace(/%currency%/i, `${productInfo?.currencieLabel}`)
        .replace(/%discount%/i, `${discount}`)
    : null;

  const addButtonText = productInfo?.quantityAvailable !== 0 ? addToCartTitle : soldOutTitle;
  const btnLabel =
    productInfo?.quantityAvailable !== 0
      ? addToCartLabel?.replace(/%title%/i, `${productTitle}`)
      : soldOutLabel?.replace(/%title%/i, `${productTitle}`);

  return {
    discountString,
    productInfo,
    checkoutAndAddProduct,
    onClose: () => setIsModalOpen(false),
    addButtonText,
    btnLabel,
  };
};

export default useShopify;
