import { SearchFormProps } from '@shared/types/umbraco';
import React, { FC, memo } from 'react';
import './SearchInput.scss';

import SearchBoxComponent from './SearchBoxComponent';

import AlgoliaSearchBox from './AlgoliaSearch';

const SearchInput: FC<SearchFormProps.ISearchForm> = ({
  ariaSearchForm,
  ariaSearchInput,
  searchPlaceholder,
  withAlgolia,
  handleCloseMenu,
  ariaClear,
  searchUrl,
}) => (
  <div className="searchinput-container">
    <form role="search" aria-label={ariaSearchForm}>
      {withAlgolia ? (
        <AlgoliaSearchBox
          searchPlaceholder={searchPlaceholder}
          ariaClear={ariaClear}
          ariaSearchInput={ariaSearchInput}
        />
      ) : (
        <SearchBoxComponent
          searchPlaceholder={searchPlaceholder}
          handleCloseMenu={handleCloseMenu}
          ariaClear={ariaClear}
          ariaSearchInput={ariaSearchInput}
          searchUrl={searchUrl}
        />
      )}
    </form>
  </div>
);

export default memo(SearchInput);
