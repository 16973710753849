import { IExtractor } from '../models';

const extractor = ({
  pageHref,
  siteSettings,
  brandSettings,
  additionalData,
  transformedSiteUrl,
}: IExtractor): object => [
  {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    name: brandSettings.brandName,
    alternateName: siteSettings.siteName,
    url: pageHref,
  },
  {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: brandSettings.brandName,
    alternateName: siteSettings.siteName,
    url: siteSettings.siteUrl,
    logo: `${transformedSiteUrl}${brandSettings.logo?.gatsbyImage?.childImageSharp?.fluid.src}`,
    sameAs: additionalData?.socialLinks || [],
  },
];

export default extractor;
