import { IExtractor } from '../models';

const extractor = ({
  pageHref,
  siteSettings,
  brandSettings,
  additionalData,
  transformedSiteUrl,
}: IExtractor): object => ({
  '@context': 'https://schema.org',
  '@type': 'Article',
  mainEntityOfPage: {
    '@type': 'WebPage',
    '@id': pageHref,
  },
  headline: additionalData.title.replace(/(<(\/?[^>]+)>)/g, ''),
  image: additionalData.image,
  author: {
    '@type': 'Organization',
    name: brandSettings.brandName,
    url: siteSettings.siteUrl,
  },
  publisher: {
    '@type': 'Organization',
    name: brandSettings.brandName,
    logo: {
      '@type': 'ImageObject',
      url: `${transformedSiteUrl}${brandSettings.logo?.gatsbyImage?.childImageSharp?.fluid.src}`,
    },
  },
});

export default extractor;
