import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';

import GatsbyImage from 'common/GatsbyImage';

import { IBackgroundImageProps } from './model';
import './BackgroundImage.scss';

const BackgroundImage: FC<IBackgroundImageProps> = ({
  backgroundProperties: {
    properties: { backgroundImage, backgroundPosition, isBackgroundFixed, useGatsbyImage },
  },
}) => (
  <div
    className={classnames('background-image', {
      'background-image--fixed': isBackgroundFixed,
    })}
  >
    {useGatsbyImage ? (
      <GatsbyImage image={backgroundImage} objectPosition={backgroundPosition || '50% 0%'} />
    ) : (
      <img
        className="background-image--img"
        src={backgroundImage.fallbackUrl}
        role="presentation"
        alt=""
        style={{ objectPosition: backgroundPosition || '50% 0%' }}
      />
    )}
  </div>
);

export const query = graphql`
  fragment FragmentPageBackgroundImage on PageBackgroundImage {
    properties {
      backgroundImage {
        ...FragmentGatsbyProps
        gatsbyImage {
          childImageSharp {
            fluid(maxHeight: 1200, quality: 100, srcSetBreakpoints: [800, 1200]) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      useGatsbyImage
      isBackgroundFixed
      backgroundPosition
    }
  }
`;

export default BackgroundImage;
