import React, { FC } from 'react';
import classNames from 'classnames';
import SearchInput from 'common/SearchInput';
import { NavigationProps } from './model';
import NavigationItem from './components/NavigationItem';
import './Navigation.scss';

const Navigation: FC<NavigationProps.INavigation> = ({
  isMenuOpen,
  navigation,
  classes,
  ariaSearchInput,
  ariaSearchForm,
  ariaSearchSubmit,
  ariaClear,
  searchPlaceholder,
  handleCloseMenu,
  searchUrl,
  currentLink,
}) => {
  const getMenu = () => (
    <ul className="navigation__list">
      {navigation?.length > 0 &&
        navigation.map((navItem) => (
          <NavigationItem
            key={navItem?.properties?.label}
            {...navItem?.properties}
            classes="navigation__list-item"
            currentLink={currentLink}
          />
        ))}
    </ul>
  );

  const getSearch = () => (
    <div className="search-container">
      <SearchInput
        searchPlaceholder={searchPlaceholder}
        ariaSearchSubmit={ariaSearchSubmit}
        ariaClear={ariaClear}
        ariaSearchInput={ariaSearchInput}
        handleCloseMenu={handleCloseMenu}
        ariaSearchForm={ariaSearchForm}
        searchUrl={searchUrl}
      />
    </div>
  );

  return (
    <nav
      data-testid="main__navigation"
      aria-label="main navigation"
      className={classNames('navigation', `${classes}`)}
    >
      {isMenuOpen ? (
        <>
          {getSearch()}
          {getMenu()}
        </>
      ) : (
        <>
          {getMenu()}
          {getSearch()}
        </>
      )}
    </nav>
  );
};

export default Navigation;
