import React from 'react';
import Button from 'common/Button';
import { BV_TIMEOUT_MS } from 'utils/constants';
import { ILanguageSelectorProps } from './model';

import './LanguageSelector.scss';

const LanguageSelector: React.FC<ILanguageSelectorProps> = ({
  inHeader,
  langs,
  redirectToHome,
  currentLang,
  alternateUrls,
  link,
}) => {
  const language = langs?.find(({ properties }) => properties.code !== currentLang)?.properties;

  const alternateUrl = alternateUrls?.filter(
    (el) => el.lang !== 'x-default' && el.path !== link
  )?.[0]?.path;

  const path = !redirectToHome && alternateUrl ? alternateUrl : language?.prefix;

  const handleBazaarVoice = () => {
    if (window.BV) {
      // TODO: BV script doesn't handle switching static pages. Problem is reportd to the BV team. If it will be fixed, this will be remowed.
      setTimeout(() => window.location.reload(), BV_TIMEOUT_MS);
    }
  };

  return inHeader && !!language ? (
    <span title={language.ariaLabel} style={{ display: 'flex', alignItems: 'center' }}>
      <Button
        variant="link"
        classes="language__button language__button--link"
        to={path}
        lang={language.code}
        onClick={handleBazaarVoice}
      >
        {language.title}
      </Button>
    </span>
  ) : null;
};

export default LanguageSelector;
