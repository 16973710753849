import { FC, useContext, useEffect } from 'react';
import useShopify from 'hooks/useShopify';
import { graphql, useStaticQuery } from 'gatsby';
import { CartContext } from 'context/CartContextProvider/CartContextProvider';
import { ISupportShopifyWrapper } from './model';

const SupportShopifyWrapper: FC<ISupportShopifyWrapper> = ({ purchaseSettings }) => {
  const { onSetPurchaseSettings } = useContext(CartContext);

  useEffect(() => {
    onSetPurchaseSettings(purchaseSettings);
  }, [JSON.stringify(purchaseSettings)]);

  const { homepage } = useStaticQuery(
    graphql`
      query defaultData {
        homepage {
          defaultCompositions {
            siteSettings {
              isShopify
              lang
            }
          }
        }
      }
    `
  );

  const { isShopify, lang } = homepage?.defaultCompositions?.siteSettings || {};

  useShopify({ isShopify, lang });

  return null;
};

export default SupportShopifyWrapper;
