import React, { FC } from 'react';
import Button from 'common/Button';
import { DangerouslySetInnerHtml } from 'layout';
import { IThankYou } from '../model';

const ThankYou: FC<IThankYou> = ({ message, cta }) => (
  <div className="signup__info signup__info--thank">
    {message ? <DangerouslySetInnerHtml className="signup__message" html={message} /> : null}

    {cta?.length ? (
      <Button
        classes="signup__message-cta"
        to={cta[0].properties.link[0].url}
        ariaLabel={cta[0].properties.ariaLabel}
        target={cta[0].properties.link[0].target}
        variant="primary"
      >
        {cta[0].properties.link[0].name}
      </Button>
    ) : null}
  </div>
);

export default ThankYou;
