import React, { FC } from 'react';
import Button from 'gatsby-theme-husky/src/common/Button';
import { DangerouslySetInnerHtml } from 'gatsby-theme-husky/src/layout';
import { IForm } from 'gatsby-theme-husky/src/components/SignUp/model';

const Form: FC<IForm> = ({
  description,
  button,
  ariaButton,
  title,
  labelEmail,
  labelFirstName,
  labelLastName,
  labelPhone,
  phonePlaceholder,
  phonePattern,
  setFormData,
  handleSubmit,
  formData,
  formState,
  disclaimer,
  errorMessage,
  setFormState,
}) => {
  const { isSending, isError } = formState;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e?.target;
    const newValue = type === 'checkbox' ? checked : value;
    setFormData((oldFormData) => ({
      ...oldFormData,
      [name]: newValue,
    }));
    setFormState((prevVal) => ({ ...prevVal, isError: false }));
  };

  return (
    <div className="signup__info">
      {title ? (
        <DangerouslySetInnerHtml element="h2" className="signup__title" html={title} />
      ) : null}
      {description ? (
        <DangerouslySetInnerHtml className="signup__description" html={description} />
      ) : null}
      <form className="signup__form" action="#" onSubmit={handleSubmit}>
        {labelLastName && (
          <div className="signup__form-group signup__form-group--last">
            <label htmlFor="last_name">{labelLastName}</label>
            <input
              onChange={handleChange}
              value={formData.lastName}
              name="lastName"
              id="last_name"
              data-testid="last_name"
              type="text"
              required
            />
          </div>
        )}
        <div className="signup__form-group signup__form-group--first">
          <label htmlFor="first_name">{labelFirstName}</label>
          <input
            onChange={handleChange}
            value={formData.first_name}
            name="first_name"
            id="first_name"
            data-testid="first_name"
            type="text"
            required
          />
        </div>
        <div className="signup__form-group signup__form-group--email">
          <label htmlFor="email">{labelEmail}</label>
          <input
            onChange={handleChange}
            value={formData.email}
            name="email"
            id="email"
            data-testid="email"
            type="email"
            required
          />
        </div>
        {labelPhone && (
          <div className="signup__form-group signup__form-group--phone">
            <label htmlFor="phone">{labelPhone}</label>
            <input
              onChange={handleChange}
              value={formData.phone}
              name="phone"
              id="phone"
              data-testid="phone"
              type="tel"
              placeholder={phonePlaceholder || null}
              pattern={phonePattern || null}
              required
            />
          </div>
        )}
        <div className="signup__form-group--rest">
          {isError && !!errorMessage && (
            <p className="signup__error" aria-errormessage={errorMessage} live-region="alert">
              {errorMessage}
            </p>
          )}
          {disclaimer && (
            <label htmlFor="signup-policy" className="signup__form-checkbox-group">
              <input
                onChange={handleChange}
                type="checkbox"
                name="requiredCheckbox"
                id="signup-policy"
                required
                checked={formData.requiredCheckbox}
              />
              <span className="checkbox" />
              <DangerouslySetInnerHtml className="signup__disclaimer" html={disclaimer} />
            </label>
          )}
          {button ? (
            <Button
              disabled={isSending}
              ariaLabel={ariaButton}
              type="submit"
              variant="primary"
              isLoading={isSending}
            >
              {button}
            </Button>
          ) : null}
        </div>
      </form>
    </div>
  );
};

export default Form;
