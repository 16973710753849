import React, { FC, useContext } from 'react';

import GatsbyImage from 'common/GatsbyImage';
import { DangerouslySetInnerHtml } from 'layout';
import { Link } from 'gatsby';
import QuantitySelector from 'common/QuantitySelector';
import Button from 'common/Button';

import { CartContext } from 'context/CartContextProvider/CartContextProvider';
import { CartItemInCartProps } from './models';

import './CartItemInCart.scss';

const CartItemInCart: FC<CartItemInCartProps> = ({
  productInfo,
  availableQuantity,
  image,
  quantity,
  oldPrice,
  price,
  title,
  productId,
  handleRemoveItem,
  cartQuantityLabel,
  cartPriceLabel,
  cartRemoveLabel,
  link,
  quantitySelectorAriaLabel,
}) => {
  const { currencieLabel, updateProductInCheckout, isLoading, purchaseSettings } =
    useContext(CartContext);
  const { oldPriceLabel, currentPriceLabel } = purchaseSettings;

  return (
    <div className="cart-item" data-testid="cart-item">
      <div className="cart-item__image-container">
        <GatsbyImage image={image?.properties?.image} objectFit="contain" />
      </div>
      <div className="cart-item__description-container">
        <Link className="description-container__title" to={link}>
          {title}
        </Link>
        {!!productInfo?.length && (
          <ul className="description-container__products-list">
            {productInfo.map((el) => (
              <li key={el}>
                <DangerouslySetInnerHtml html={el} />
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="cart-item__quantity-container">
        <div className="quantity-container__title">{cartQuantityLabel}</div>
        <div className="quantity-container__quantity">
          <QuantitySelector
            isLoading={isLoading}
            increaseHandler={() => updateProductInCheckout(productId, quantity + 1)}
            decreaseHandler={() => updateProductInCheckout(productId, quantity - 1)}
            orderQuantity={quantity}
            availableProductQuantity={availableQuantity}
            quantitySelectorAriaLabel={quantitySelectorAriaLabel}
          />
          <span className="quantity__number">{quantity}</span>
        </div>
      </div>
      <div className="cart-item__price-container">
        <div className="price-container__title">{cartPriceLabel}</div>
        <div className="price-container__price">
          {oldPrice ? (
            <div className="price__old-price">
              <span className="sr-only">{oldPriceLabel}</span>
              {currencieLabel}
              {oldPrice}
            </div>
          ) : null}
          <div className="price__new-price">
            <span className="sr-only">{currentPriceLabel}</span>
            {currencieLabel}
            {price}
          </div>
        </div>
      </div>
      <div className="cart-item__remove-container">
        <Button
          disabled={isLoading}
          classes="remove-container__button"
          clickHandler={() => handleRemoveItem(productId)}
          variant="secondary-link"
        >
          {cartRemoveLabel}
        </Button>
      </div>
    </div>
  );
};

export default CartItemInCart;
