import { SignUpPopup } from '@shared/types/umbraco';

export function getSignUpPopupVisibilityState(
  signUpPopup: Pick<SignUpPopup.ISignUpPopup, 'isHomePageOnly' | 'disableOnPages'>,
  link: string
): boolean {
  if (!signUpPopup) return false;

  const { isHomePageOnly, disableOnPages } = signUpPopup;

  if (isHomePageOnly) return link === '/';

  if (Array.isArray(disableOnPages) && disableOnPages.length)
    return !disableOnPages.some(({ url }) => url === link);

  return true;
}
