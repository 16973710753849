import React, { FC, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import classNames from 'classnames';

import { ISingupFormPlaceholderFront } from '@shared/types/frontend/compositions';
import { Container } from 'layout';

import { initialFormState, initialFormData } from '../formConstants';
import { submitFormData } from '../actions';
import { NEWSLETTER_SIGN_UP } from '../constants';
import Form from './Form';
import '../SignUp.scss';

const SignUpComponentWithoutImage: FC<ISingupFormPlaceholderFront> = ({
  singupFormBaner = {},
  renderForm,
  wideMode,
  alignImage,
  thankYouComponent,
  callbackFunction,
  signupType,
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [formState, setFormState] = useState(initialFormState);
  const [formData, setFormData] = useState(initialFormData);

  const { errorMessage, formConfig } = singupFormBaner || {};
  const { isCDSMode, isCheckboxRequired } = formConfig?.[0]?.properties || {};
  const signupBannerVariant = signupType?.[0];

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!executeRecaptcha) {
      return;
    }

    const recaptchaResponse = await executeRecaptcha(NEWSLETTER_SIGN_UP);

    const formDataSubmit = {
      firstName: formData.firstName,
      email: formData.email,
      phone: formData.phone,
      lastName: formData.lastName,
      extraPolicy: formData.extraPolicy,
      policy: formData.policy,
      additionalPolicy: formData.additionalPolicy,
    };

    await submitFormData({
      setFormState,
      formConfig,
      isCDSMode,
      url: process.env.GATSBY_CDP_LAMBDA_URL,
      callbackFunction,
      formData: formDataSubmit,
      recaptchaResponse,
    });
  };

  return renderForm ? (
    <div
      className={classNames('signup', 'signup--dark-theme', {
        [`signup--image-${alignImage?.[0]}`]: alignImage,
        'signup--wide-mode': wideMode,
        [`signup--${signupBannerVariant}`]: signupBannerVariant,
      })}
      data-testid="signup"
    >
      <Container fluid>
        <div className="signup__grid">
          {!formState.isSubmitted ? (
            <Form
              {...{
                ...singupFormBaner,
                formData,
                setFormData,
                handleSubmit,
                formState,
                errorMessage,
                setFormState,
                isCheckboxRequired,
                signupType,
              }}
            />
          ) : null}
          {formState.isSubmitted && !!thankYouComponent ? thankYouComponent : null}
        </div>
      </Container>
    </div>
  ) : null;
};

export default SignUpComponentWithoutImage;
