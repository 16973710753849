import Button from 'common/Button';
import React, { FC } from 'react';
import { IQuantitySelector } from './model';
import './QuantitySelector.scss';

const QuantitySelector: FC<IQuantitySelector> = ({
  isLoading,
  decreaseHandler,
  increaseHandler,
  orderQuantity,
  availableProductQuantity = 0,
  quantitySelectorAriaLabel,
}) => (
  <div className="quantity-selector">
    <Button
      ariaLabel="quantity-decrease"
      data-testid="quantity-decrease"
      disabled={orderQuantity < 2 || isLoading}
      variant="icon"
      classes="quantity-selector__button"
      iconSuffix="minus"
      clickHandler={decreaseHandler}
    />
    <input
      aria-label={quantitySelectorAriaLabel}
      value={orderQuantity}
      className="quantity-selector__quantity"
      readOnly
    />
    <Button
      ariaLabel="quantity-increase"
      data-testid="quantity-increase"
      disabled={orderQuantity >= availableProductQuantity || isLoading}
      variant="icon"
      classes="quantity-selector__button"
      iconSuffix="plus"
      clickHandler={increaseHandler}
    />
  </div>
);

export default QuantitySelector;
