import { FormStateFields, FormDateFields } from './model';

const initialFormState: FormStateFields = {
  isSent: false,
  isSending: false,
  isError: false,
  isSubmitted: false,
};

const initialFormData: FormDateFields = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  acceptMarketing: false,
  requiredCheckbox: false,
  policy: false,
  additionalPolicy: false,
  extraPolicy: false,
  consentPolicy: false,
  consent: false,
  additionalConsent: false,
};

export { initialFormState, initialFormData };
