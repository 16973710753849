import React, { FC, useEffect, useMemo, useState } from 'react';
import { graphql } from 'gatsby';
import { Container, DangerouslySetInnerHtml } from 'layout';
import { IWarningFront } from '@shared/types/frontend/compositions';
import { useLocation } from '@reach/router';
import Icon from 'common/IconCustom';
import Button from 'common/Button';
import classnames from 'classnames';
import './Warning.scss';

const Warning: FC<IWarningFront> = ({
  warning: { description, title, link, ariaClose },
  navigation,
}) => {
  const location = useLocation();
  const isSubNavigationPresent = useMemo(() => {
    const activeNavItem = navigation?.find(
      (item) =>
        item?.properties?.link?.[0] &&
        item.properties.link[0].properties.link[0].url !== '/' &&
        location?.href?.includes(item.properties.link[0].properties.link[0].url)
    );

    return !!activeNavItem?.properties?.subNavigation.length;
  }, []);
  const [isVisible, setIsVisible] = useState(false);
  const closeHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    sessionStorage.setItem('WARNING_WAS_SHOWN', 'true');
    setIsVisible(false);
  };

  useEffect(() => {
    const seenBefore = sessionStorage.getItem('WARNING_WAS_SHOWN');
    if (!seenBefore) {
      setIsVisible(true);
    }
  }, []);

  const Cta = ({
    properties: {
      ariaLabel,
      link: [{ name, url, target }],
    },
  }) => (
    <Button
      iconSuffix="chevron-right"
      ariaLabel={ariaLabel}
      variant="secondary-link"
      to={url}
      target={target}
    >
      {name}
    </Button>
  );

  return (
    <div
      open={isVisible}
      aria-hidden={!isVisible}
      className={classnames('warning', {
        'warning--shift': isSubNavigationPresent,
      })}
      data-testid="warning"
    >
      <Container fluid>
        <div className="warning__grid">
          <Icon icon="warning" />
          <div className="warning__main">
            {title && (
              <DangerouslySetInnerHtml className="warning__title" element="h2" html={title} />
            )}
            {description && (
              <DangerouslySetInnerHtml className="warning__description" html={description} />
            )}
            {!!link?.length && <Cta {...link[0]} />}
          </div>
          <Button clickHandler={closeHandler} variant="icon" iconPrefix="close">
            <span className="sr-only">{ariaClose}</span>
          </Button>
        </div>
      </Container>
    </div>
  );
};

export const query = graphql`
  fragment FragmentWarning on TWarning {
    title
    description
    link {
      properties {
        link {
          url
          name
          target
        }
        ariaLabel
      }
    }
    ariaClose
  }
`;

export default Warning;
