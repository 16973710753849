import React, { FC } from 'react';

import articleExtractor from './extractors/article';
import pageExtractor from './extractors/page';
import faqExtractor from './extractors/faq';
import productExtractor from './extractors/product';
import howToExtractor from './extractors/howTo';

import { PageSchemaProps, ExtractorsMapObject } from './models';

const extractorsMap: ExtractorsMapObject = {
  Article: articleExtractor,
  default: pageExtractor,
  FAQ: faqExtractor,
  Product: productExtractor,
  HowTo: howToExtractor,
};

const PageSchema: FC<PageSchemaProps> = ({
  type,
  path = '',
  siteSettings,
  brandSettings,
  seoData,
  additionalData,
}) => {
  const { siteUrl = '' } = siteSettings || {};

  const transformedSiteUrl = siteUrl.slice(-1) === '/' ? siteUrl.slice(0, -1) : siteUrl;
  const pageHref = path ? `${transformedSiteUrl}${path}` : siteUrl;

  const extractor = extractorsMap[type] || extractorsMap.default;
  const jsonLd = extractor({
    pageHref,
    siteSettings,
    brandSettings,
    seoData,
    additionalData,
    transformedSiteUrl,
  });

  const structuredData = JSON.stringify(jsonLd);

  return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: structuredData }} />;
};

export default PageSchema;
