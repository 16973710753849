import React, { FC, useCallback } from 'react';

import Button from 'common/Button';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import CheckboxField from 'common/CheckboxField';
import { handleChangeFormData } from 'components/SignUp/actions';

import { IForm } from 'components/SignUp/model';

const FormPopup: FC<IForm> = ({
  button,
  ariaButton,
  title,
  labelEmail,
  labelCheckbox,
  setFormData,
  handleSubmit,
  formData,
  formState,
  disclaimer,
  errorMessage,
  setFormState,
  collapsibleCheckboxText,
  additionalLabelCheckbox,
  additionalCollapsibleCheckboxText,
  isCheckboxRequired,
  extraLabelCheckbox,
}) => {
  const { isSending, isError } = formState;

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeFormData(event, setFormData, setFormState);
  }, []);

  return (
    <div className="signupForm-popup__info">
      <DangerouslySetInnerHtml element="h2" className="signupForm-popup__title" html={title} />
      <form action="#" className="signupForm-popup__form" onSubmit={handleSubmit}>
        <div className="signupForm-popup__form-content">
          <div className="signupForm-popup__form-group signupForm-popup__form-group--email">
            <label htmlFor="email">{labelEmail}</label>
            <input
              onChange={handleChange}
              value={formData.email}
              name="email"
              id="email"
              data-testid="email"
              type="email"
              required
            />
          </div>
          <div className="signupForm-popup__form-group--rest">
            {isError && !!errorMessage && (
              <p
                className="signupForm-popup__error"
                aria-errormessage={errorMessage}
                // eslint-disable-next-line react/no-unknown-property
                live-region="alert"
              >
                {errorMessage}
              </p>
            )}
            {extraLabelCheckbox ? (
              <CheckboxField
                id="signup-form-consentPolicy"
                name="consentPolicy"
                handleChange={handleChange}
                value={formData.consentPolicy}
                isRequired={isCheckboxRequired}
                labelText={extraLabelCheckbox}
              />
            ) : null}
            {labelCheckbox ? (
              <CheckboxField
                id="signupForm-popup-form-consent"
                name="consent"
                handleChange={handleChange}
                value={formData.consent}
                isRequired={isCheckboxRequired}
                labelText={labelCheckbox}
                collapsedText={collapsibleCheckboxText}
              />
            ) : null}
            {additionalLabelCheckbox ? (
              <CheckboxField
                id="signupForm-popup-form-additionalConsent"
                name="additionalConsent"
                handleChange={handleChange}
                value={formData.additionalConsent}
                isRequired={isCheckboxRequired}
                labelText={additionalLabelCheckbox}
                collapsedText={additionalCollapsibleCheckboxText}
              />
            ) : null}
            <DangerouslySetInnerHtml className="signupForm-popup__disclaimer" html={disclaimer} />
          </div>
        </div>
        <Button
          disabled={isSending}
          ariaLabel={ariaButton}
          type="submit"
          variant="primary"
          isLoading={isSending}
        >
          {button}
        </Button>
      </form>
    </div>
  );
};

export default FormPopup;
