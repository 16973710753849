import { IExtractor } from '../models';

const extractor = ({ additionalData, transformedSiteUrl }: IExtractor): object => ({
  '@context': 'https://schema.org/',
  '@id': 'HowTo',
  '@type': 'HowTo',
  name: additionalData.title.replace(/(<(\/?[^>]+)>)/g, ''),
  image: additionalData.image?.gatsbyImage?.childImageSharp?.fluid.src
    ? `${transformedSiteUrl}${additionalData.image?.gatsbyImage?.childImageSharp?.fluid.src}`
    : '',
  step: additionalData.steps.map((el) => ({
    '@type': 'HowToStep',
    text: el.properties.description.replace(/(<(\/?[^>]+)>)/g, ''),
    image: el.properties?.image?.[0]?.properties?.image?.gatsbyImage?.childImageSharp?.fluid.src
      ? `${transformedSiteUrl}${el.properties?.image?.[0]?.properties?.image?.gatsbyImage?.childImageSharp?.fluid.src}`
      : '',
  })),
});

export default extractor;
