import React, { FC, useState, useCallback, useEffect, useRef } from 'react';
import { graphql } from 'gatsby';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import GatsbyImage from 'common/GatsbyImage';
import Button from 'common/Button';
import { getCookies, setCookies } from 'utils/cookies';
import useClickOutside from 'hooks/useClickOutside';
import { SignupFormPopup as SignupFormPopupProps } from '@shared/types';
import { initialFormState, initialFormData } from 'components/SignUp/formConstants';
import { submitFormData } from 'components/SignUp/actions';

import FormPopup from './FormPopup';
import { SIGNUP_POPUP_COOKIE_NAME, SIGNUP_POPUP_TIMEOUT, NEWSLETTER_SIGN_UP } from '../constants';
import '../SignUpFormPopup.scss';

const SignUpFormPopup: FC<SignupFormPopupProps.IData> = ({
  closeButton,
  productsImage,
  button,
  ariaButton,
  title,
  labelEmail,
  labelCheckbox,
  disclaimer,
  errorMessage,
  collapsibleCheckboxText,
  additionalLabelCheckbox,
  additionalCollapsibleCheckboxText,
  extraLabelCheckbox,
  signupType,
  formConfig,
  callbackFunction,
  isWithoutSessionMode,
  message,
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [formState, setFormState] = useState(initialFormState);
  const [formData, setFormData] = useState(initialFormData);
  const { isCDSMode, isCheckboxRequired } = formConfig?.[0]?.properties || {};
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isLoaded, setisLoaded] = useState<boolean>(false);
  const [cookiePopupValue, setCookiePopupValue] = useState<string>('');
  const containerRef = useRef(null);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!executeRecaptcha) {
      return;
    }

    const recaptchaResponse = await executeRecaptcha(NEWSLETTER_SIGN_UP);

    const formDataSubmit = {
      firstName: formData.firstName,
      email: formData.email,
      phone: formData.phone,
      lastName: formData.lastName,
      extraPolicy: formData.consentPolicy,
      policy: formData.consent,
      additionalPolicy: formData.additionalConsent,
    };

    await submitFormData({
      setFormState,
      formConfig,
      isCDSMode,
      url: process.env.GATSBY_CDP_LAMBDA_URL,
      callbackFunction,
      formData: formDataSubmit,
      recaptchaResponse,
    });
  };

  useEffect(() => {
    isVisible
      ? document.body.classList.add('no-scroll')
      : document.body.classList.remove('no-scroll');

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isVisible]);

  useEffect(() => {
    setCookiePopupValue(getCookies(SIGNUP_POPUP_COOKIE_NAME));
    setCookies(SIGNUP_POPUP_COOKIE_NAME, 'false', '/', 30);
    setisLoaded(true);
  }, []);

  useEffect(() => {
    if (cookiePopupValue !== 'true' && isLoaded) {
      const timeout = setTimeout(() => {
        setIsVisible(true);
      }, SIGNUP_POPUP_TIMEOUT);

      return () => clearTimeout(timeout);
    }
  }, [isLoaded]);

  const onCloseModal = useCallback(() => {
    setIsVisible(false);
    if (!isWithoutSessionMode) {
      setCookies(SIGNUP_POPUP_COOKIE_NAME, 'true', '/', 30);
    }
  }, []);

  useClickOutside(containerRef, onCloseModal);

  return (
    <div ref={containerRef} id="sign-up-popup">
      {isVisible ? (
        <section className="signupForm-popup">
          <div className="signupForm-popup__content">
            {productsImage?.[0]?.properties.image ? (
              <div className="signupForm-popup__img">
                <GatsbyImage
                  image={productsImage[0].properties.image}
                  alt={productsImage[0].properties?.imageAlt}
                />
              </div>
            ) : null}
            {!formState.isSubmitted ? (
              <FormPopup
                {...{
                  errorMessage,
                  title,
                  labelEmail,
                  labelCheckbox,
                  button,
                  ariaButton,
                  disclaimer,
                  formData,
                  setFormData,
                  handleSubmit,
                  formState,
                  setFormState,
                  isCheckboxRequired,
                  signupType,
                  collapsibleCheckboxText,
                  additionalLabelCheckbox,
                  additionalCollapsibleCheckboxText,
                  extraLabelCheckbox,
                  formConfig,
                }}
              />
            ) : null}
            {formState.isSubmitted ? (
              <div className="signupForm-popup__info signupForm-popup__info--thank">
                <DangerouslySetInnerHtml className="signup__message" html={message} />
              </div>
            ) : null}
            <Button
              variant="primary"
              classes="signupForm-popup__close"
              ariaLabel={closeButton}
              clickHandler={onCloseModal}
            >
              {closeButton}
            </Button>
          </div>
        </section>
      ) : null}
    </div>
  );
};

export const query = graphql`
  fragment FragmentSignUpFormPopup on TSignUpFormPopup {
    closeButton
    productsImage {
      properties {
        imageAlt
        image {
          ...FragmentGatsbyProps
          gatsbyImage {
            childImageSharp {
              fluid(maxWidth: 302) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    title
    description
    button
    ariaButton
    errorMessage
    formConfig {
      properties {
        headers
        body
        cdp
        revisionIds {
          BusinessId
          RevisionId
          ConsentDesc
          MandatoryInd
          ConsentAcceptedInd
          AgreementDate
          ActivityDate
        }
        isCDSMode
        isCheckboxRequired
      }
    }
    labelEmail
    labelCheckbox
    collapsibleCheckboxText
    additionalLabelCheckbox
    additionalCollapsibleCheckboxText
    extraLabelCheckbox
    signupType
    disclaimer
    message
  }
`;

export default SignUpFormPopup;
