import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

import { PriceSpiderProps } from './models';

const PriceSpider: FC<PriceSpiderProps> = ({ psKey, psCountry, psLanguage, psScript }) => (
  <Helmet>
    <meta name="ps-key" content={psKey} />
    <meta name="ps-country" content={psCountry} />
    <meta name="ps-language" content={psLanguage} />
    <script src={psScript} async />
  </Helmet>
);

export default PriceSpider;
