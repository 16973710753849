import React, { FC } from 'react';
import classNames from 'classnames';

import GatsbyImage from 'common/GatsbyImage';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';

import { ITrademarkProps } from './model';

const Trademark: FC<ITrademarkProps> = ({ trademark, placement }) => {
  const { logo, logoAlt, text } = trademark?.[0]?.properties || {};

  return (
    <div
      className={classNames('footer-top__trademark', {
        [`footer-top__trademark--${placement}-placement`]: placement,
      })}
    >
      <GatsbyImage image={logo} className="footer-top__trademark-logo" alt={logoAlt} />
      <DangerouslySetInnerHtml className="footer-top__trademark-text" html={text} />
    </div>
  );
};

export default Trademark;
