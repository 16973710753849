import React, { FC } from 'react';

import GatsbyImage from 'common/GatsbyImage';
import Button from 'common/Button';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import { IDefaultPopupContentProps } from 'components/SignUpPopup/model.d';

const DefaultPopupContent: FC<IDefaultPopupContentProps> = ({
  link,
  logo,
  closeButton,
  onCloseModal,
  textAfterLogo,
  productsImage,
  textBeforeLogo,
  backgroundImage,
  isBigImageStyle,
  productsDescription,
  backgroundImageMobile,
  screen: { isDesktop },
}) => (
  <>
    <div className="signup-popup__scroll-wrapper">
      {isDesktop || isBigImageStyle ? (
        <div className="signup-popup__products">
          {logo?.[0]?.properties.image ? (
            <GatsbyImage
              image={logo[0].properties.image}
              alt={logo[0].properties?.imageAlt}
              style={{ position: 'absolute' }}
              className="signup-popup__products-logo"
            />
          ) : null}
          <DangerouslySetInnerHtml
            html={productsDescription}
            className="signup-popup__products-description"
          />
          {productsImage?.[0]?.properties.image ? (
            <GatsbyImage
              image={productsImage[0].properties.image}
              alt={productsImage[0].properties?.imageAlt}
              style={{ position: 'absolute' }}
              objectFit={isBigImageStyle ? 'contain' : 'cover'}
              className="signup-popup__products-image"
            />
          ) : null}
        </div>
      ) : null}

      <div className="signup-popup__content">
        <p className="signup-popup__text">
          {textBeforeLogo}
          {logo?.[0]?.properties.image ? (
            <GatsbyImage
              image={logo[0].properties.image}
              objectFit="fill"
              alt={logo[0].properties?.imageAlt}
              style={{ position: 'absolute' }}
              className="signup-popup__logo"
            />
          ) : null}
          {textAfterLogo}
        </p>

        <Button
          variant="primary"
          classes="signup-popup__link"
          ariaLabel={link?.[0]?.properties?.ariaLabel}
          target={link?.[0]?.properties?.link[0].target}
          to={link?.[0]?.properties?.link[0].url}
        >
          {link?.[0]?.properties?.link[0].name}
        </Button>

        {backgroundImageMobile?.[0]?.properties.image && !isDesktop ? (
          <GatsbyImage
            image={backgroundImageMobile[0].properties.image}
            alt={backgroundImageMobile[0].properties?.imageAlt}
            style={{ position: 'absolute' }}
            className="signup-popup__background"
          />
        ) : backgroundImage?.[0]?.properties.image ? (
          <GatsbyImage
            image={backgroundImage[0].properties.image}
            alt={backgroundImage[0].properties?.imageAlt}
            style={{ position: 'absolute' }}
            className="signup-popup__background"
          />
        ) : null}
      </div>
    </div>

    <Button
      variant="primary"
      classes="signup-popup__close"
      ariaLabel={closeButton}
      clickHandler={onCloseModal}
    >
      {closeButton}
    </Button>
  </>
);

export default DefaultPopupContent;
