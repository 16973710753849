import React, { FC } from 'react';

import { Container } from 'layout';
import Button from 'common/Button';
import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import Icon from 'common/IconCustom/IconCustom';
import { Footer } from '@shared/types/umbraco/compositions';
import './FooterBottom.scss';

const FooterBottom: FC<Footer.IBottomFooter> = ({ copyright, socialNetworks }) => {
  const copyString = copyright
    ? `© ${new Date().getFullYear()} ${copyright}`
    : `© ${new Date().getFullYear()}`;

  return (
    <div className="footer-bottom" data-testid="footer-bottom">
      <Container fluid>
        <div className="footer-bottom__grid">
          <DangerouslySetInnerHtml className="footer-bottom__copy" html={copyString} />
          {socialNetworks?.length ? (
            <ul className="footer-bottom__links">
              {socialNetworks.map(({ properties: { icon, link, ariaLabel } }) => (
                <li key={ariaLabel}>
                  <Button
                    variant="icon"
                    to={link?.[0].url}
                    target={link?.[0]?.target}
                    ariaLabel={ariaLabel}
                  >
                    <Icon icon={icon?.[0]} />
                    <span className="sr-only">{ariaLabel}</span>
                  </Button>
                </li>
              ))}
            </ul>
          ) : null}
        </div>
      </Container>
    </div>
  );
};

export default FooterBottom;
