export const isEmpty = (obj): boolean => {
  if (obj === undefined || obj === null) {
    return true;
  }
  if (Array.isArray(obj)) {
    return !obj.length;
  }
  if (typeof obj === 'object') {
    return !Object.keys(obj).length;
  }

  return !obj;
};

export const isValidUrl = (url: string) => {
  try {
    new URL(url);

    return true;
  } catch (err) {
    return false;
  }
};

export const isValidNumber = (num: unknown) => typeof num === 'number' && !Number.isNaN(num);

export default isEmpty;
