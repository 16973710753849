import { useState, useCallback, useEffect } from 'react';

import { getCookies, setCookies } from 'utils/cookies';

import { POPUP_CONFIG } from '../constants';
import { HookReturnValue } from '../model';

export default function useDefaultPopupBehavior(
  isWithoutSessionMode: boolean,
  delayTimeout?: number
): HookReturnValue {
  const [isVisible, setIsVisible] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [cookiePopupValue, setCookiePopupValue] = useState('');

  useEffect(() => {
    setCookiePopupValue(getCookies(POPUP_CONFIG.name));
    setCookies(POPUP_CONFIG.name, POPUP_CONFIG.state.initial, '/', POPUP_CONFIG.expire);
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (cookiePopupValue !== POPUP_CONFIG.state.seen && isLoaded) {
      timeout = setTimeout(() => {
        setIsVisible(true);
      }, delayTimeout || POPUP_CONFIG.delayTimeout);
    }

    return () => clearTimeout(timeout);
  }, [isLoaded]);

  const onCloseHandler = useCallback(() => {
    setIsVisible(false);
    if (!isWithoutSessionMode) {
      setCookies(POPUP_CONFIG.name, POPUP_CONFIG.state.seen, '/', POPUP_CONFIG.delayTimeout);
    }
  }, []);

  return [isVisible, onCloseHandler];
}
