import { useState, useEffect } from 'react';
import { isBrowser } from 'utils/browser';
import { TABLET_BREAKPOINT, DESKTOP_BREAKPOINT } from 'utils/constants';
import { UseScreenRecognitionHook } from './model';

const useScreenRecognition: UseScreenRecognitionHook = () => {
  const browserAvaliable = isBrowser();
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const [isNotMobile, setIsNotMobile] = useState(false);

  const handleResize = () => {
    const width = browserAvaliable && window.innerWidth;

    setIsMobile(width < TABLET_BREAKPOINT);
    setIsNotMobile(width >= TABLET_BREAKPOINT);
    setIsTablet(width >= TABLET_BREAKPOINT && width < DESKTOP_BREAKPOINT);
    setIsDesktop(width >= DESKTOP_BREAKPOINT);
  };

  useEffect(() => {
    handleResize();
    browserAvaliable && window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    isMobile,
    isTablet,
    isDesktop,
    isNotMobile,
  };
};

export default useScreenRecognition;
