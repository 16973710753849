import React, { FC, useContext } from 'react';
import Button from 'common/Button';
import { Header } from '@shared/types/umbraco';
import { CartContext } from 'context/CartContextProvider/CartContextProvider';
import IconCustom from 'common/IconCustom';

const TopHeaderBlock: FC<{
  block: Header.ITopHeaderBlockEl[];
}> = ({ block }) => {
  const { cartItemsQuantity } = useContext(CartContext);

  return (
    <div data-testid="navigation__block" className="navigation__block-wrapper">
      {block.map((element) =>
        element.properties?.icon?.length ? (
          <div className="navigation__button-wrapper" key={element.properties.link[0].name}>
            <Button
              to={element.properties.link[0].url}
              variant="icon"
              classes="navigation__button"
              ariaLabel={element.properties.ariaLabel}
              target={element.properties.link[0].target}
            >
              <IconCustom icon={element.properties.icon[0]} />
            </Button>
            {element.properties.icon[0] === 'cart' && cartItemsQuantity > 0 && (
              <div className="navigation__button__cart-quantity">{cartItemsQuantity}</div>
            )}
          </div>
        ) : (
          <Button
            key={element.properties.link[0].url}
            variant="icon"
            to={element.properties.link[0].url}
            classes="navigation__button"
            ariaLabel={element.properties.ariaLabel}
            target={element.properties.link[0].target}
          >
            {element.properties.link[0].name}
          </Button>
        )
      )}
    </div>
  );
};

export default TopHeaderBlock;
