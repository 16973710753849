import React, { FC, useEffect } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import FocusTrap from 'focus-trap-react';

import { Header } from '@shared/types/umbraco';
import Button from 'common/Button';
import Container from 'layout/Container/Container';
import ConditionalWrapper from 'common/ConditionalWrapper/ConditionalWrapper';
import GatsbyImage from 'common/GatsbyImage';

import { isBrowser } from 'utils/browser';
import useScreenRecognition from 'hooks/useScreenRecognition';
import Navigation from '../Navigation';
import TopHeaderBlock from '../TopHeader/components/TopHeaderBlock';

const MainHeader: FC<Header.IMainHeader> = ({
  handleOpenMenu,
  isMenuOpen,
  handleCloseMenu,
  logo,
  navigation,
  ariaSearch,
  ariaBurger,
  ariaSearchSubmit,
  ariaSearchForm,
  ariaSearchInput,
  ariaClear,
  searchPlaceholder,
  searchUrl,
  currentLink,
  topHeaderRightBlock,
  topHeaderLeftBlock,
}) => {
  const { isDesktop } = useScreenRecognition();

  useEffect(() => {
    isDesktop && handleCloseMenu();
  }, [isDesktop]);

  useEffect(() => {
    const burgerElem = document.querySelector('.icon--menu');
    if (!isMenuOpen && burgerElem) {
      burgerElem.closest('button').focus();
    }
  }, [isMenuOpen]);

  useEffect(() => {
    if (isBrowser() && isMenuOpen) {
      const rootEl = document.querySelector('html');
      rootEl?.classList.add('modal-is-open');

      return () => {
        rootEl?.classList.remove('modal-is-open');
      };
    }
  }, [isBrowser(), isMenuOpen]);

  const getLogo = () => {
    const { link, image, imageAlt } = logo?.[0]?.properties || {};

    const imageLink = link?.length ? link?.[0].url : '/';

    return image ? (
      <Link className="header-logo" tabindex={isMenuOpen && '-1'} to={imageLink}>
        <GatsbyImage
          image={image}
          alt={imageAlt || 'Finish'}
          className="logo__img"
          objectFit="contain"
          isLazyLoading={false}
          fadeIn={false}
        />
      </Link>
    ) : null;
  };

  return (
    <div
      data-testid="header__main"
      className={classNames('header__main', { open: isMenuOpen }, 'header__main--dark-theme')}
    >
      <Container fluid>
        <ConditionalWrapper
          condition={!isDesktop && isMenuOpen}
          wrapper={(children) => (
            <FocusTrap focusTrapOptions={{ clickOutsideDeactivates: true }}>{children}</FocusTrap>
          )}
        >
          <div className="header__main-flex">
            {getLogo()}
            {!!navigation?.length && (
              <Navigation
                isMenuOpen={isMenuOpen}
                navigation={navigation}
                classes={isMenuOpen ? 'open' : ''}
                handleCloseMenu={handleCloseMenu}
                ariaSearch={ariaSearch}
                ariaSearchInput={ariaSearchInput}
                ariaSearchForm={ariaSearchForm}
                ariaSearchSubmit={ariaSearchSubmit}
                ariaClear={ariaClear}
                searchPlaceholder={searchPlaceholder}
                searchUrl={searchUrl}
                currentLink={currentLink}
              />
            )}
            <div className="header__main__functional-buttons">
              {topHeaderLeftBlock && <TopHeaderBlock block={topHeaderLeftBlock} />}
              {topHeaderRightBlock && <TopHeaderBlock block={topHeaderRightBlock} />}
              <Button
                variant="icon"
                clickHandler={isMenuOpen ? handleCloseMenu : handleOpenMenu}
                classes="burger-navigation__button"
                ariaLabel={ariaBurger}
                aria-expanded={isMenuOpen}
                iconPrefix={isMenuOpen ? 'close' : 'menu'}
              >
                <span className="sr-only">{ariaBurger}</span>
              </Button>
            </div>
          </div>
        </ConditionalWrapper>
      </Container>
    </div>
  );
};

export default MainHeader;
