export const TABLET_BREAKPOINT = 768;
export const DESKTOP_BREAKPOINT = 992;
export const ANCHOR_LINKS_SETTINGS = {
  spy: true,
  hashSpy: false,
  activeClass: 'active',
  smooth: true,
  duration: 300,
  offset: 0,
};

export default {
  TABLET_BREAKPOINT,
  DESKTOP_BREAKPOINT,
  ANCHOR_LINKS_SETTINGS,
};

export const specialSymbols = {
  tickSymbol: '\u2713',
};

export const DATA_LAYER_WATER_CALCULATOR = {
  QUESTION_STEP: 'stepscreenview',
  RESULT_STEP: 'resultscreenview',
  CHALLENGE_DETAILS_SCREEN: 'challengecreenview',
  THANK_YOU_SCREEN: 'thankyouscreenview',
  SIGN_UP_SCREEN: 'signupscreenview',
  LAST_SHARE_SCREEN: 'sharescreenview',
};

export const BV_SCRIPT_HOST = 'https://apps.bazaarvoice.com/deployments';
export const BV_TIMEOUT_MS = 100;
