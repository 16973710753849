import React, { FC, useMemo } from 'react';
import NavigationItem from '../NavigationItem';
import { ISubmenu } from './model';

const Dropdown: FC<ISubmenu> = ({ navigation, currentLang, langs, currentLink = '' }) => {
  const currentPrefix = langs?.find(({ properties }) => properties.code === currentLang)?.properties
    ?.prefix;

  const subNavigation = useMemo(() => {
    const baseUrl = currentPrefix || '/';
    const activeNavItem = navigation?.find(
      (item) =>
        item?.properties?.link?.[0] &&
        item.properties.link[0].properties.link[0].url !== baseUrl &&
        currentLink.includes(item.properties.link[0].properties.link[0].url)
    );

    return activeNavItem?.properties?.subNavigation || [];
  }, []);

  return subNavigation?.length ? (
    <nav className="sub-navigation sub-navigation--dark-theme" aria-label="Sub navigation">
      <ul className="navigation__dropdown">
        {subNavigation.map((navItem) => (
          <NavigationItem
            key={navItem?.properties?.label}
            label={navItem?.properties?.label}
            link={navItem?.properties?.link}
            classes="navigation__dropdown-item"
            currentLink={currentLink}
          />
        ))}
      </ul>
    </nav>
  ) : null;
};

export default Dropdown;
