import React, { FC, useState } from 'react';
import { navigate } from 'gatsby';

import { gtmService } from 'utils/gtmService';

import IconCustom from '../IconCustom';
import Button from '../Button';

import { ISearchBox } from './model';

const SearchBoxComponent: FC<ISearchBox> = ({
  searchPlaceholder,
  ariaClear,
  ariaSearchInput,
  handleCloseMenu,
  searchUrl,
}) => {
  const [inputValue, setInputValue] = useState<string>('');

  const goToSearchPage = (searchQuery) => {
    navigate(encodeURI(`${searchUrl}?q=${searchQuery}`));
    setInputValue('');
  };

  const onKeyPress = (e): void => {
    if (e.key === 'Enter') {
      e.preventDefault();
      inputValue && goToSearchPage(inputValue);
      handleCloseMenu && handleCloseMenu();
      gtmService.emitSearchResultsView(inputValue);
    }
  };

  const onChange = (e): void => setInputValue(e.target.value);

  const closeIconOnClick = () => {
    setInputValue('');
  };

  return (
    <>
      <input
        type="search"
        className="searchinput"
        placeholder={searchPlaceholder}
        aria-label={ariaSearchInput}
        onChange={onChange}
        value={inputValue}
        onKeyPress={onKeyPress}
      />
      {inputValue?.length > 0 ? (
        <Button variant="icon" clickHandler={closeIconOnClick} ariaLabel={ariaClear}>
          <IconCustom icon="close" />
        </Button>
      ) : (
        <IconCustom icon="search" />
      )}
    </>
  );
};

export default SearchBoxComponent;
