import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';

import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import { IGatsbyImageComponentProps } from './models';
import './GatsbyImage.scss';

const GatsbyImage: FC<IGatsbyImageComponentProps> = ({
  isLazyLoading = true,
  alt = '',
  className = '',
  objectFit,
  objectPosition,
  image,
  useFocalPoint,
  fadeIn = true,
  ...restProps
}) => {
  const padY = parseInt(image?.focalPoint?.y || '50', 10);
  const padX = parseInt(image?.focalPoint?.x || '50', 10);
  const svgRest: { [key: string]: any } = restProps;
  const isSvg = image?.svg?.content;
  const loading = isLazyLoading ? 'lazy' : 'eager';

  const imageProps = {
    className,
    alt,
    objectFit: objectFit || 'cover',
    objectPosition: useFocalPoint ? `${padX}% ${padY}%` : objectPosition || '50% 50%',
    ...restProps,
  };

  return (
    <>
      {isSvg ? (
        <DangerouslySetInnerHtml
          html={image.svg.content}
          element="div"
          className={`gatsby-svg-image ${className}`}
          title={alt}
          {...svgRest}
        />
      ) : null}
      {!isSvg && image?.gatsbyImage?.childImageSharp?.fixed ? (
        <Img
          {...imageProps}
          fixed={image?.gatsbyImage.childImageSharp.fixed}
          loading={loading}
          fadeIn={fadeIn}
        />
      ) : null}
      {!isSvg && image?.gatsbyImage?.childImageSharp?.fluid ? (
        <Img
          {...imageProps}
          fluid={{ ...image?.gatsbyImage.childImageSharp.fluid, media: '(min-width: 240px)' }}
          loading={loading}
          fadeIn={fadeIn}
        />
      ) : null}
    </>
  );
};

export const query = graphql`
  fragment FragmentSmallImage on File {
    childImageSharp {
      fluid(maxWidth: 500) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }

  fragment FragmentMiddleImage on File {
    childImageSharp {
      fluid(maxWidth: 950) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }

  fragment FragmentLargeImage on File {
    childImageSharp {
      fluid(maxWidth: 1920) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }

  fragment FragmentGatsbyProps on TGatsbyImageProperties {
    fallbackUrl
    url
    focalPoint {
      type
      x
      y
    }
    svg {
      content
    }
  }

  fragment FragmentGatsbyImage on TGatsbyImageProperties {
    ...FragmentGatsbyProps
    gatsbyImage {
      ...FragmentLargeImage
    }
  }

  fragment FragmentGatsbyImageSm on TGatsbyImageProperties {
    ...FragmentGatsbyProps
    gatsbyImage {
      ...FragmentSmallImage
    }
  }

  fragment FragmentGatsbyImageMd on TGatsbyImageProperties {
    ...FragmentGatsbyProps
    gatsbyImage {
      ...FragmentMiddleImage
    }
  }

  fragment FragmentGatsbyImageLg on TGatsbyImageProperties {
    ...FragmentGatsbyProps
    gatsbyImage {
      ...FragmentLargeImage
    }
  }
`;

export default GatsbyImage;
