import { FC } from 'react';

interface ConditionalWrapProps {
  condition: boolean;
  wrapper?: (children: JSX.Element) => JSX.Element;
  children: JSX.Element;
}

const ConditionalWrapper: FC<ConditionalWrapProps> = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

export default ConditionalWrapper;
