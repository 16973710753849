import React, { FC, useMemo } from 'react';
import classnames from 'classnames';
import { Navigation } from '@shared/types/umbraco/compositions';
import Button from 'common/Button';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';

const NavigationItem: FC<Navigation.INavigationItem> = ({
  link,
  label,
  classes,
  currentLink = '',
}) => {
  const isOpen = useMemo(
    () =>
      link?.[0]?.properties?.link?.[0]?.url?.length > 1 &&
      currentLink.includes(link[0].properties.link[0].url),
    [currentLink, JSON.stringify(link)]
  );

  // const location = useLocation();
  const navigationItemCssClassNames = classnames({
    open: isOpen,
    [classes]: classes,
  });

  return (
    <li data-testid="nav-item" key={label} className={navigationItemCssClassNames}>
      <Button
        ariaLabel={link?.[0]?.properties?.ariaLabel}
        classes={classnames({ active: isOpen })}
        variant="link"
        to={link?.[0]?.properties?.link?.[0]?.url}
        activeClassName="active"
      >
        <DangerouslySetInnerHtml html={label} element="span" />
      </Button>
    </li>
  );
};

export default NavigationItem;
