import React, { FC } from 'react';
import { Link } from 'gatsby';

import Icon from 'common/IconCustom';
import GatsbyImage from 'common/GatsbyImage';
import Button from 'common/Button';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import { IDiscountPopupContentProps } from 'components/SignUpPopup/model.d';

const DiscountPopupContent: FC<IDiscountPopupContentProps> = ({
  text,
  link,
  screen,
  closeButton,
  onCloseModal,
  productsImage,
  backgroundImage,
  backgroundImageMobile,
}) => {
  const { image, imageAlt } = productsImage[0].properties;
  const {
    link: [{ url, target, name }],
    ariaLabel,
  } = link[0].properties;
  const popupBackgroundImage = screen.isNotMobile ? backgroundImage : backgroundImageMobile;

  return (
    <>
      <div className="signup-popup__wrapper">
        <div className="signup-popup__image-holder">
          <GatsbyImage
            className="signup-popup__image"
            image={image}
            alt={imageAlt}
            objectFit="contain"
          />
        </div>
        <div className="signup-popup__content-holder">
          <DangerouslySetInnerHtml html={text} className="signup-popup__text" />
          {target === '_blank' ? (
            <a
              href={url}
              className="signup-popup__discount-link"
              rel="noopener nofollow"
              aria-label={ariaLabel}
              onClick={onCloseModal}
              target={target}
            >
              <span className="signup-popup__link-text">{name}</span>
              <Icon classes="icon__suffix" icon="chevron-right" />
            </a>
          ) : (
            <Link
              to={url}
              className="signup-popup__discount-link"
              aria-label={ariaLabel}
              onClick={onCloseModal}
            >
              <span className="btn__inner">{name}</span>
              <Icon classes="icon__suffix" icon="chevron-right" />
            </Link>
          )}
        </div>
        <GatsbyImage
          className="signup-popup__background"
          image={popupBackgroundImage[0].properties.image}
          alt={popupBackgroundImage[0].properties?.imageAlt}
          style={{ position: 'absolute' }}
        />
      </div>
      <Button
        variant="primary"
        classes="signup-popup__close"
        iconSuffix="close"
        ariaLabel={closeButton}
        clickHandler={onCloseModal}
      />
    </>
  );
};

export default DiscountPopupContent;
