import { IExtractor } from '../models';

const extractor = ({ additionalData }: IExtractor): object => ({
  '@context': 'https://schema.org',
  '@id': 'FAQPage',
  '@type': 'FAQPage',
  mainEntity: additionalData.faqData.map((el) => ({
    '@type': 'Question',
    name: el.label.replace(/(<(\/?[^>]+)>)/g, ''),
    acceptedAnswer: {
      '@type': 'Answer',
      text: el.content.replace(/(<(\/?[^>]+)>)/g, ''),
    },
  })),
});

export default extractor;
