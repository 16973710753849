import React, { FC, useContext, useEffect } from 'react';
import { navigate } from 'gatsby-link';

import { isBrowser } from 'utils/browser';
import FocusTrap from 'focus-trap-react';

import { specialSymbols } from 'utils/constants';

import Button from 'common/Button';
import { CartContext } from 'context/CartContextProvider/CartContextProvider';
import CartItemInCart from 'components/CartItemInCart';
import { ICartModal } from './model';

import './CartModal.scss';

const CartModal: FC<ICartModal> = ({ cartPath }) => {
  const {
    purchaseSettings,
    currentProduct,
    cartItemsQuantity,
    currencieLabel,
    totalPrice,
    setIsModalOpen,
  } = useContext(CartContext);

  const {
    itemsInCartTitle,
    addToCartMessage,
    continueShoppingPath,
    checkoutButtonLabel,
    cartQuantityLabel,
    totalTitle,
    cartPriceLabel,
  } = purchaseSettings;

  const onClose = () => setIsModalOpen(false);

  const escKeyListener = (e) => {
    if (e.keyCode === 27) {
      onClose();
    }
  };

  const preventOtsideClick = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    if (isBrowser()) {
      const rootEl = document.querySelector('html');
      rootEl?.classList.add('modal-is-open');
      document.addEventListener('keydown', escKeyListener);

      return () => {
        rootEl?.classList.remove('modal-is-open');
        document.removeEventListener('keydown', escKeyListener);
      };
    }
  }, [isBrowser()]);

  const {
    productInfo,
    availableQuantity,
    image,
    quantity,
    oldPrice,
    price,
    title,
    description,
    productId,
    link,
  } = currentProduct || {};

  return (
    <div onClick={onClose} className="cart-modal__blur" data-testid="cart-modal">
      <FocusTrap focusTrapOptions={{ clickOutsideDeactivates: true }}>
        <div onClick={preventOtsideClick} className="cart-modal" data-testid="cart-modal-container">
          <CartItemInCart
            cartQuantityLabel={cartQuantityLabel}
            cartPriceLabel={cartPriceLabel}
            productInfo={productInfo}
            availableQuantity={availableQuantity}
            image={image}
            quantity={quantity}
            oldPrice={oldPrice}
            price={price}
            title={title}
            description={description}
            productId={productId}
            link={link}
          />
          <div className="summary-info">
            {itemsInCartTitle ? (
              <div className="summary-info__items-quantity">
                <div className="items-quantity__title">{itemsInCartTitle}</div>
                <div className="items-quantity__items">{cartItemsQuantity}</div>
              </div>
            ) : null}
            {totalTitle ? (
              <div className="summary-info__total">
                <div className="total__title">{totalTitle}</div>
                <div className="total__sum">
                  {currencieLabel}
                  {totalPrice}
                </div>
              </div>
            ) : null}
          </div>
          <div className="control-buttons">
            {checkoutButtonLabel ? (
              <Button
                ariaLabel={cartPath?.[0]?.properties?.ariaLabel}
                classes="control-buttons__checkout"
                variant="secondary"
                clickHandler={() => {
                  onClose();
                  navigate(cartPath?.[0]?.properties?.link?.[0]?.url);
                }}
              >
                {checkoutButtonLabel}
              </Button>
            ) : null}
            {continueShoppingPath ? (
              <Button
                ariaLabel={continueShoppingPath?.[0]?.properties?.link?.[0]?.name}
                clickHandler={onClose}
                classes="control-buttons__continue"
                variant="secondary"
              >
                {continueShoppingPath?.[0]?.properties?.link?.[0]?.name}
              </Button>
            ) : null}
          </div>
          <div className="cart-modal__upper-side">
            {addToCartMessage ? (
              <div className="upper-side__message">
                <span className="message__mark">{specialSymbols.tickSymbol}</span>
                {addToCartMessage}
              </div>
            ) : null}
            <Button
              classes="upper-side__close-button"
              iconPrefix="plus"
              clickHandler={onClose}
              variant="icon"
            />
          </div>
        </div>
      </FocusTrap>
    </div>
  );
};

export default CartModal;
