import React, { FC, useContext } from 'react';
import Portal from 'common/Portal';
import CartModal from 'common/CartModal';
import { CartContext } from 'context/CartContextProvider/CartContextProvider';
import { ICartModalWrapper } from './model';

const CartModalWrapper: FC<ICartModalWrapper> = ({ cartPath }) => {
  const { isModalOpen } = useContext(CartContext);

  return isModalOpen ? (
    <Portal>
      <CartModal cartPath={cartPath} />
    </Portal>
  ) : null;
};

export default CartModalWrapper;
