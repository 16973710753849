import React, { FC } from 'react';
import { Element } from 'react-scroll';
import ConditionalWrapper from '../ConditionalWrapper/ConditionalWrapper';
import { IBodyStructureElement, IPropsBodyRenderer } from './model';

const BodyRenderer: FC<IPropsBodyRenderer> = ({
  bodyData,
  bodyStructure,
  bodyItemProps,
}): React.ReactElement | null => {
  if (bodyData?.length === 0) return null;

  return (
    <>
      {bodyData.map((bodyItem: IBodyStructureElement, index: number) => {
        const keyId = `${bodyItem.structure}_${index}`;

        return bodyStructure[bodyItem.structure] ? (
          <ConditionalWrapper
            key={keyId}
            condition={bodyItem.properties?.showInMenu}
            wrapper={(children) => (
              <Element name={bodyItem.properties?.anchorName} className="element">
                {children}
              </Element>
            )}
          >
            {bodyStructure[bodyItem.structure](
              {
                properties: { ...bodyItem.properties, ...bodyItemProps },
                structure: bodyItem.structure,
              },
              keyId
            )}
          </ConditionalWrapper>
        ) : null;
      })}
    </>
  );
};

export default BodyRenderer;
