import React, { FC, useEffect, useState } from 'react';
import connectSearchBox from 'react-instantsearch-core/dist/es/connectors/connectSearchBox';

import Button from 'common/Button';
import { gtmService } from 'utils/gtmService';

import { IAlgoliaSearchbox } from './model';

import IconCustom from '../IconCustom';
import { getQueryStringVal, setQueryStringVal } from '../../utils/useQueryParam';

const SearchBoxComponent: FC<IAlgoliaSearchbox> = ({
  refine,
  searchPlaceholder,
  currentRefinement,
  ariaClear,
  ariaSearchInput,
}) => {
  const [inputValue, setInputValue] = useState<string>(currentRefinement);

  const refineFunction = (valueToRefine) => {
    refine(valueToRefine);
    setQueryStringVal('q', encodeURI(valueToRefine));
  };

  const curValue = getQueryStringVal('q');

  useEffect(() => {
    setInputValue(curValue);
    refine(curValue);
  }, [curValue]);

  const onKeyPress = (e): void => {
    if (e.key === 'Enter') {
      e.preventDefault();
      inputValue && refineFunction(inputValue);
      gtmService.emitSearchResultsView(inputValue);
    }
  };

  const onChange = (e): void => setInputValue(e.target.value);

  const closeIconOnClick = (): void => {
    setInputValue('');
  };

  return (
    <>
      <input
        type="search"
        className="searchinput"
        placeholder={searchPlaceholder}
        aria-label={ariaSearchInput}
        onChange={onChange}
        value={inputValue}
        onKeyPress={onKeyPress}
      />
      {inputValue?.length > 0 ? (
        <Button variant="icon" clickHandler={closeIconOnClick} ariaLabel={ariaClear}>
          <IconCustom icon="close" />
        </Button>
      ) : (
        <IconCustom icon="search" />
      )}
    </>
  );
};

const AlgoliaSearch = connectSearchBox(SearchBoxComponent);

export default AlgoliaSearch;
