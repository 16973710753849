import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Footer as FooterProps } from '@shared/types/umbraco/compositions';
import FooterTop from 'gatsby-theme-husky/src/components/Footer/components/FooterTop';
import FooterBottom from 'gatsby-theme-husky/src/components/Footer/components/FooterBottom';
import './Footer.scss';

const Footer: FC<FooterProps.IFooter> = ({
  copyright,
  socialNetworks,
  links,
  additionalLinks,
  crossLinks,
  crossTitle,
  logo,
  navigationTitle,
  socialNetworksTitle,
  signUp,
  withSignUp = false,
  trademark,
}) => (
  <footer className="footer">
    <FooterTop
      {...{
        links,
        additionalLinks,
        crossLinks,
        crossTitle,
        logo,
        navigationTitle,
        socialNetworks,
        socialNetworksTitle,
        signUp,
        trademark,
      }}
      signUp={withSignUp && signUp}
    />
    <FooterBottom {...{ copyright, socialNetworks, additionalLinks }} />
  </footer>
);

export const query = graphql`
  fragment FragmentFooter on TFooterComposition {
    copyright
    additionalLinks {
      properties {
        ariaLabel
        link {
          name
          target
          url
        }
        openCookiePopup
      }
    }
    crossLinks {
      properties {
        ariaLabel
        link {
          name
          target
          url
        }
        openCookiePopup
      }
    }
    crossTitle
    links {
      properties {
        ariaLabel
        link {
          name
          target
          url
        }
        openCookiePopup
      }
    }
    logo {
      properties {
        ariaLabel
        imageAlt
        link {
          url
        }
        image {
          fallbackUrl
          svg {
            content
          }
        }
      }
    }
    trademark {
      properties {
        text
        logoAlt
        logo {
          ...FragmentGatsbyProps
          gatsbyImage {
            childImageSharp {
              fixed(width: 20) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      }
    }
    socialNetworks {
      properties {
        ariaLabel
        icon
        link {
          name
          url
          target
        }
      }
    }
    navigationTitle
    socialNetworksTitle
    withSignUp
  }
`;

export default Footer;
