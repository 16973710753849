import { RefObject, useEffect } from 'react';

const useClickOutside = (
  ref: RefObject<HTMLDivElement>,
  callBack: () => void,
  ignoreClickOutside?: boolean
): void => {
  useEffect(() => {
    if (ignoreClickOutside) return;

    const handleClickOutside = (event: MouseEvent | any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callBack();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callBack]);
};

export default useClickOutside;
